<template>
	<div :id="id" ref="custom-modal" aria-hidden="true">
		<!-- Backdrop -->
		<div class="popup-backdrop" ref="modalBackdrop" :class="{ show: showing, hide: hideDataBackdrop }" @click="handleBackdropClick"></div>

		<!-- Popup Container -->
		<div
			class="popup-container modal-content add-section-modal"
			ref="modalContent"
			:class="{ show: showing, framed: !frame }"
			:style="computedStyle"
			:aria-hidden="!showing"
		>
			<div v-if="!hideClose" class="stan-modal-header">
				<div class="close close-modal" aria-label="Close" @click="hide">
					<InlineSvg class="close-icon" src="/images/icons/ic-close.svg" />
				</div>
			</div>
			<div class="d-flex w-100 justify-content-center modal-heading">
				<InlineSvg v-if="titleIcon" class="mr-2" :src="titleIcon" />
				<h3 v-if="title" class="h3 text-bold">{{ title }}</h3>
			</div>
			<div class="modal-inner-content" :class="{ 'overflow-visible': overflow }">
				<div v-if="subheading" class="modal-subheading para-2">
					{{ subheading }}
				</div>
				<div>
					<slot></slot>
				</div>
				<div class="d-flex flex-column gap v-gap mt-3 pt-1" v-if="action">
					<AppButton
						:name="`cta-button-modal-${id}`"
						:icon="actionIcon"
						:color="actionColor"
						:loading="loading"
						:disabled="disabled"
						@click="ctaClick"
						v-if="action"
						>{{ action }}</AppButton
					>
					<AppButton
						:name="`secondary-cta-button-modal-${id}`"
						class="p-0 h-auto"
						link
						:loading="loading"
						:disabled="disabled"
						:color="secondaryActionColor"
						@click="secondaryCtaClick"
						v-if="secondaryAction"
						>{{ secondaryAction }}</AppButton
					>
					<slot name="footer"></slot>
				</div>
				<span v-if="footerNote.length" class="w-100 d-flex justify-content-center mt-3 para-4 text-light text-center">
					{{ footerNote }}
				</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'AppModalBottomPopup',
		props: {
			id: {
				type: String,
				default: '',
			},
			transitionKey: {
				default: '',
			},
			subheading: {
				type: String,
				default: undefined,
			},
			action: {
				type: String,
				default: '',
			},
			actionIcon: {
				type: String,
				default: '',
			},
			actionColor: {
				type: String,
				default: 'primary',
			},
			secondaryAction: {
				type: String,
				default: '',
			},
			secondaryActionColor: {
				type: String,
				default: 'primary',
			},
			title: {
				type: String,
				default: '',
			},
			titleIcon: {
				type: String,
				default: '',
			},
			extraLarge: {
				type: Boolean,
				default: false,
				description: 'Used to make the modal look wider',
			},
			large: {
				type: Boolean,
				default: false,
				description: 'Used to make the modal look wider',
			},
			frame: {
				type: Boolean,
				default: false,
				description: 'Used to remove all formatting and offer empty canvas',
			},
			fade: {
				type: Boolean,
				default: true,
				description: 'Whether the modal should fade in/out',
			},
			position: {
				type: String,
				default: 'center',
			},
			loading: {
				type: Boolean,
				default: false,
				description: 'Used to have the button on the modal show loading hollow dot spinners',
			},
			disableAction: {
				type: Boolean,
				default: false,
				description: 'Used to disable the button on the modal',
			},
			isVideo: {
				type: Boolean,
				default: false,
				description: 'Used to indicate if the modal is used as a video player',
			},
			staticDataBackdrop: {
				type: Boolean,
				default: false,
				description: 'Used to prevent the modal from being closed when clicking outside.',
			},
			hideDataBackdrop: {
				type: Boolean,
				default: false,
				description: 'Used to hide the background',
			},
			hideClose: {
				type: Boolean,
				default: false,
				description: 'Used to hide the close button in case we want a non-dismissable modal.',
			},
			overflow: {
				type: Boolean,
				default: false,
				description: 'Used to determine if the modal should allow overflow.',
			},
			width: {
				type: Number,
				description: 'Sets the modal to a custom width',
			},
			footerNote: {
				type: String,
				description: 'Goes below action button for very small notes.',
				default: '',
			},
		},
		data() {
			return {
				showing: false,
			}
		},
		mounted() {
			$(this.$refs['custom-modal']).on('shown.bs.modal', () => {
				this.showing = true
			})
			$(this.$refs['custom-modal']).on('hidden.bs.modal', () => {
				this.showing = false
			})
		},
		computed: {
			disabled() {
				return this.disableAction || this.loading
			},
			computedStyle() {
				if (this.width !== null) {
					return { width: `${this.width}px` }
				}

				return null
			},
		},
		methods: {
			ctaClick() {
				this.$emit('cta')
			},
			secondaryCtaClick() {
				this.$emit('secondary-cta')
			},
			handleBackdropClick() {
				// Only use this as a fallback if the modal background is not visible
				if (!this.staticDataBackdrop) {
					this.hide()
				}
			},
			handleEscPressed(event) {
				if (event.key === 'Escape') {
					this.hide()
				}
			},
			show() {
				this.showing = true
				document.addEventListener('keydown', this.handleEscPressed)
				this.$emit('open')
			},
			hide() {
				this.showing = false
				document.removeEventListener('keydown', this.handleEscPressed)
				this.$emit('close')
			},
		},
	}
</script>

<style lang="scss" scoped>
	/* Backdrop Styles */
	.popup-backdrop {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		opacity: 0;
		transition: opacity 0.3s ease;
		z-index: 1000;
		display: none;

		&.show {
			display: block;
			opacity: 1;
		}
		&.hide {
			background: rgba(0, 0, 0, 0);
		}
	}

	/* Popup Container Styles */
	.popup-container {
		position: fixed;
		transform: translateY(100%); /* Start off-screen */
		left: 0;
		bottom: 0;
		width: 100%;
		transition: transform 0.3s ease-in-out;
		z-index: 1001;

		&.show {
			transform: translateY(0); /* Slide in and stick to the bottom */
		}
	}

	.modal-content {
		max-height: 90vh;
		border-radius: 1.25rem 1.25rem 0 0; /* Only round top */
		border: none;
		&.framed {
			padding: 30px 25px;
		}
		.modal-heading {
			text-align: center;
			margin-bottom: 20px;
		}
	}

	.stan-modal-header {
		position: relative;
		height: 25px;
		.close-modal {
			// position: absolute;
			// right: 0;
			display: flex;
			width: 28px;
			height: 28px;
			background-color: var(--stan-gray-light-color) !important;
			border-radius: 50%;
			cursor: pointer;
			z-index: 9;
			color: var(--stan-primary-primary-color);
			opacity: 1;
			&:hover {
				background-color: var(--stan-gray-soft-color) !important;
			}

			.close-icon {
				margin: auto;
			}
		}
	}

	.modal-inner-content {
		overflow: auto;

		.modal-subheading {
			width: 85%;
			text-align: center;
			margin: auto;
			margin-bottom: 10px;
			color: var(--stan-text-light-color);
		}
	}
</style>

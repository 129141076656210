var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "selectable-card d-flex flex-column justify-content-between",
      class: {
        active: this.active,
        "md-small-num": this.mdSmallNum,
        "md-big-num": this.mdBigNum,
        selectable: this.selectable
      },
      style: _vm.activeCardClass,
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "loading-skeleton" },
            [_c("Skeleton", { attrs: { heading: false, lines: 2 } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "s-card-title h5 d-flex align-items-center justify-content-between flex-wrap"
            },
            [
              _c(
                "div",
                { staticClass: "text-nowrap d-flex align-items-center" },
                [
                  _vm.icon
                    ? _c("InlineSvg", {
                        staticClass: "s-card-icon mr-1",
                        attrs: { src: _vm.icon }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.title))]),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "d-lg-none mx-2",
                    style: _vm.activeIndicatorClass
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.warningIndicator && _vm.warningIndicator.showIndicator
                ? _c(
                    "AppTooltip",
                    {
                      attrs: {
                        click: true,
                        html: true,
                        tip: _vm.warningIndicator.indicatorTooltipText
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex align-items-center" }, [
                        _c("div", {
                          staticClass: "warning-indicator",
                          class: `indicator-${_vm.warningIndicator.indicatorType}`
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "para-2 text-dark" }, [
                          _vm._v(_vm._s(_vm.warningIndicator.indicatorText))
                        ])
                      ])
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading
        ? _c("div", { staticClass: "s-card-body" }, [
            _c(
              "div",
              {
                staticClass: "s-card-number h1 text-bold",
                class: {
                  "md-small-num": this.mdSmallNum,
                  "md-big-num": this.mdBigNum
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "d-flex",
                    class: {
                      "align-items-center": !_vm.alignBottom,
                      "align-items-end": _vm.alignBottom
                    }
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          _vm.mainNumPrefix +
                            _vm.formattedMainDisplayNumber +
                            _vm.mainNumSuffix
                        ) +
                        "\n\t\t\t\t"
                    ),
                    _c("div", { staticClass: "ml-2" }, [_vm._t("default")], 2)
                  ]
                )
              ]
            ),
            _vm._v(" "),
            !_vm.loading && _vm.showTrend
              ? _c(
                  "div",
                  {
                    staticClass: "s-card-percentage",
                    class: { "trend-decrease": _vm.trendPercentage < 0 }
                  },
                  [
                    _vm.trendPercentage < 0
                      ? _c("InlineSvg", {
                          staticClass: "trend-icon",
                          attrs: { src: "/images/icons/ic-decrease.svg" }
                        })
                      : _c("InlineSvg", {
                          staticClass: "trend-icon",
                          attrs: { src: "/images/icons/ic-increase.svg" }
                        }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "trend-percentage h3",
                        class: { decrease: _vm.trendPercentage < 0 }
                      },
                      [_vm._v(_vm._s(_vm.trendPercentageText) + "%")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
	<div class="countdown-timer d-flex flex-column py-5 align-items-center" :class="{ 'd-none': !isCounterAvailable }" :key="resetKey">
		<div class="header" v-if="counterTitle">
			{{ counterTitle }}
		</div>
		<div class="stan-countdown-timer overflow-visible">
			<div v-if="days">{{ days }}d </div> 
			<div v-if="hours">{{ hours }}h</div> 
			<div>{{ minutes }}m</div> 
			<div>{{ seconds }}s</div>
		</div>
		
	</div>
</template>
<script>
	export default {
		props: {
			countdownTimer: { type: Object, default: undefined },
			pageId: { type: Number, default: 0 },
		},
		data() {
            return {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
				distance: 0,
				resetKey: 0,
                timer: null,
            }
        },
		mounted() {
			if (this.countdownTimer?.enabled) {
				this.startCountdown()
			}
		},
		computed: {
			isCounterAvailable() {
				return this.countdownTimer?.enabled && this.distance > 0
			},
			counterTitle() {
				return this.countdownTimer?.title
			},
		},
		methods: {
			startCountdown() {
				let endDate = 0 
				if (this.countdownTimer.timer_option === 'absolute' && this.countdownTimer.end_date_time) {
					this.endDateTime = this.countdownTimer.end_date_time
					if (!this.endDateTime) return
					endDate = new Date(this.endDateTime).getTime()
					if (isNaN(endDate)) return
				}
				let now = new Date().getTime()
				let distance = endDate - now
				this.distance = distance
				this.calculation(distance)
                this.timer = setInterval(() => {
					now = new Date().getTime()
					distance = endDate - now
					this.distance = distance
                    this.calculation(distance)
                }, 1000)
            },
			calculation(distance) {                    
					if (distance < 1) {
						if (this.timer) clearInterval(this.timer)
						this.resetKey++
                        this.days = this.hours = this.minutes = this.seconds = 0
												
                    } else {
                        this.days = Math.floor(distance / (1000 * 60 * 60 * 24))
                        this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
                        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
                        this.seconds = Math.floor((distance % (1000 * 60)) / 1000)
                    }
			}
		},
		beforeDestroy() {
			clearInterval(this.timer)
		}
	}
</script>
<style lang="scss" scoped>
	.pb-5 {
		padding-bottom: 2rem;
	}
	.stan-countdown-timer {
		margin-top: 10px ;
		display: flex;
	    gap: 10px;
		div {
			font-weight: bold;
			font-size: 1.5rem;
			padding: 5px;
            background: rgba(255, 255, 255, 0.15);
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
			border-radius: 10px;
			display: flex;
			align-items: center;
			width: 70px;
			height: 70px;
			justify-content: center;
		}

	}	
</style>